.uploads {

	&__wrapper {

		display: flex;
		align-items: center;
		text-align: center;

		&--text {

			flex-direction: column;
		}

		&.description-below {

			margin-bottom: 8px;

			@include breakpoint(xlarge) {

				margin-bottom: 16px;
			}
		}
	}

	&__title {

		padding-left: 8px;
		font-size: $font-size-xs;
		line-height: $line-height-s;

		@include breakpoint(xlarge) {

			padding-left: 16px;
			font-size: $font-size-s;
			line-height: $line-height-s;
		}

		&--accordion {

			padding-left: 0;
			font-weight: $global-weight-bold;
		}
	}

	&__size {

		font-size: $font-size-xs;
		line-height: $line-height-s;
		padding-left: 8px;
	}

	&__description {

		margin-left: 32px;

		font-size: $font-size-xs;
		line-height: $line-height-s;

		@include breakpoint(xlarge) {

			margin-left: 48px;

		}
	}
}
