//Styles for ../Partials/DocCheckForm.html
.doc-check-login {
	position: absolute;
	top: 100%;
	z-index: -1;
	width: 100%;

	overflow: hidden;

	background-color: $white;

	transform: translateY(-100%);

	transition: transform .3s ease-out;

	&.is-open {
		border-bottom: 2px solid $primary-color;
		transform: translateY(0);

		@include breakpoint(xlarge) {
			border-bottom: 4px solid $primary-color;
		}
	}
}
