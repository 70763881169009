.introtext {

	display: block;
	font-family: $body-font-family;
	color: $grey-80;

	&--xs {

		font-size: $font-size-xs;
		line-height: $line-height-s;
	}

	&--s {

		font-size: $font-size-xs;
		line-height: $line-height-s;
		margin-top: 1rem;

		@include breakpoint(medium) {

			font-size: $font-size-s;
			line-height: $line-height-s;
			margin-top: 1.5rem;
		}

		@include breakpoint(xlarge) {

			margin-top: 1rem;
		}
	}

	&--m {

		font-size: $font-size-m;
		line-height: $line-height-m;
	}

	&--l {

		font-size: $font-size-l;
		line-height: $line-height-l;
	}

	&--xl {

		font-size: $font-size-xl;
		line-height: $line-height-xl;
	}

	&--xxl {

		font-size: $font-size-xxl;
		line-height: $line-height-xxl;
	}
}
