//Styles for overview Box
.overview-box {

	margin-bottom: 24px;
	text-align: center;

	@include breakpoint(medium) {

		margin-bottom: 56px;
	}

	&__image {
		position: relative;
		z-index: 1;
		height: 0;
		width: 100%;
		padding-bottom: 66%;

		margin-bottom: 8px;
		background-size: cover;
		background-position: center center;

		@include breakpoint(medium) {

			height: 192px;
			padding-bottom: 0;
		}

		@include breakpoint(xlarge) {

			height: 248px;
			margin-bottom: 16px;
		}
	}

	&__title {

		font-size: $font-size-xs;
		line-height: $line-height-s;
		font-weight: bold;

		@include breakpoint(medium) {

			font-size: $font-size-s;
			line-height: $line-height-s;
		}

		@include breakpoint(xlarge) {

			font-size: $font-size-m;
			line-height: $line-height-m;
			margin-bottom: 8px;
		}
	}

	&__text {

		margin-bottom: 16px;
		line-height: $line-height-s;

		@include breakpoint(xlarge) {

			margin-bottom: 32px;
		}
	}

	&__button {
		margin-bottom: 16px;

		@include breakpoint(xlarge) {
			margin-bottom: 24px;
		}
	}

	// Following ist just for Slider Fallback: Slider depends on amount of content-elements inside and the current Breakpoint (Go to: Partial Overview.Slider.html)
	&__show {

		display: none;

		&.box-small {

			@include breakpoint(small) {

				display: block;
			}

			@include breakpoint(smedium) {

				display: none;
			}
		}

		&.box-smedium {

			@include breakpoint(smedium) {

				display: block;
			}

			@include breakpoint(medium) {

				display: none;
			}
		}

		&.box-medium {

			@include breakpoint(medium) {

				display: block;
			}

			@include breakpoint(xlarge) {

				display: none;
			}
		}

		&.box-xlarge {

			@include breakpoint(xlarge) {

				display: block;
			}
		}
	}
}
