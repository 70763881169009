.button {
	padding: .375rem 1rem;
	border-radius: 4px;
	cursor: pointer;
	background-color: $primary-color;
	color: $white;
	font-weight: 700;
	border: .125rem solid $primary-color;
	transition: background-color .3s ease-in-out, color .3s ease-in-out;

	&:hover {
		background-color: $white;
		color: $primary-color;
	}

	&__white {
		padding: .5rem 1rem;
		border: 0;
		background-color: $white;
		color: $primary-color;
		transition: background-color .3s ease-in-out;
	}

	&__doc-check-login {
		padding: .5rem 1rem;
		width: 100%;

		@include breakpoint(smedium) {
			width: auto;
		}
	}
}

// Fix mobile Button Layout
[type='button'] {
	-webkit-appearance: none;
}
