//Styles for ../Templates/DocCheck/Form.html
.doc-check-form {

	text-align: center;
	padding-top: 40px;

	@include breakpoint(medium) {
		padding-top: 96px;
	}

	&__wrapper {

		display: flex;
		flex-direction: column;

		&--bottom {
			margin-bottom: 3.5rem;

			@include breakpoint(xlarge) {

				margin-bottom: 5rem;
			}
		}
	}

	&__logo {

		display: none;

		@include breakpoint(medium) {

			float: left;
			display: block;
		}
	}

	&__input {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include breakpoint(medium) {
			margin-top: 1rem;
		}

		@include breakpoint(xlarge) {
			margin-top: 1.5rem;
		}

		&--username,
		&--password {
			width: 100%;

			@include breakpoint(smedium) {
				width: rem-calc(341);
			}

			@include breakpoint(xlarge) {
				width: rem-calc(376);
			}
		}
	}

	&__links {

		cursor: pointer;
		color: $grey-80;

		margin-bottom: rem-calc(48);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(152);
		}

		@include breakpoint(xlarge) {
			margin-bottom: rem-calc(144);
		}

		&--text {

			margin-bottom: .5rem;
			font-size: $font-size-xxs;
			line-height: $line-height-s;

			@include breakpoint(medium) {

				font-size: $font-size-xs;
				line-height: $line-height-s;
			}
		}
	}
}
