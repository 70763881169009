//Styles for News Detail Page
.news-detail {

	&__header {
		text-align: center;
		color: $grey-80;
	}

	&__date {
		font-size: $font-size-xxs;
		line-height: $line-height-s;

		@include breakpoint(medium) {
			font-size: $font-size-xs;
			line-height: $font-size-s;
		}

		@include breakpoint(xlarge) {
			font-size: $font-size-s;
			line-height: $font-size-s;
		}
	}

	&__headline {

		padding-top: 8px;
		padding-bottom: 8px;

		@include breakpoint(medium) {
			padding-top: 24px;
			padding-bottom: 16px;
		}

		@include breakpoint(xlarge) {
			padding-top: 40px;
			padding-bottom: 24px;
		}
	}

	&__teaser {

		p {

			font-size: $font-size-xs;
			line-height: $line-height-s;

			@include breakpoint(medium) {
				font-size: $font-size-s;
				line-height: $line-height-s;
			}

			@include breakpoint(xlarge) {
				font-size: $font-size-m;
				line-height: $line-height-m;
			}
		}
	}

	&__categorie {

		margin-bottom: 8px;
		font-size: $font-size-xxs;
		line-height: $line-height-s;


		@include breakpoint(medium) {

			margin-bottom: 16px;
			font-size: $font-size-xs;
			line-height: $font-size-s;
		}

		@include breakpoint(xlarge) {

			margin-bottom: 24px;
			font-size: $font-size-s;
			line-height: $font-size-s;
		}
	}

	&__link {
		display: flex;
		align-items: center;
	}
}


