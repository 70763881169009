// Styles for News and Event List (For Details: news-detail, For List Item: news-item)
.news-list {

	&__nav {
		display: flex;
		justify-content: center;
		font-size: $font-size-xs;
		line-height: $font-size-s;

		@include breakpoint(medium) {

			font-size: $font-size-s;
			line-height: $font-size-s;
		}

		&--current {
			font-weight: $global-weight-bold;
		}

		ul {

			li {
				float: left;
				display: block;
				padding-right: 24px;

				background: none;

				@include breakpoint(xlarge) {

					padding-right: 32px;
				}
			}
		}

		&__link {

			&--inactive {

				pointer-events: none;
				cursor: default;

				svg {

					rect {

						stroke-opacity: .5;
					}

					path {

						fill-opacity: .5;
					}
				}
			}
		}
	}
}

.no-news-found {

	font-weight: $global-weight-bold;
	color: $warning-color;

	padding-bottom: 58px;

	@include breakpoint(medium) {
		padding-bottom: 80px;
	}

	@include breakpoint(large) {
		padding-bottom: 120px;
	}
}
