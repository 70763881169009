::placeholder {
  color: $grey-80;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $grey-80;
}

::-ms-input-placeholder {
  color: $grey-80;
}

::-ms-clear {
  display: none;
}

::-ms-expand {
  display: none;
}

%label-base {
  position: absolute;
  left: 0;
  z-index: 1;
  color: $grey-50;
  line-height: $line-height-s;
  font-size: $font-size-xs;
  transition: top .2s ease, left .2s ease, color .2s ease, font-size .2s ease, opacity .2s ease;

  @include breakpoint(medium) {
    font-size: $font-size-s;
  }

  a {
    color: $la-orange;

    &:hover {
      text-decoration: underline;
    }
  }

  .has-error & {
    color: $c-red;

    a {
      color: $c-red;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }


}

%input-base {
  background: $white;
  color: $grey-80;
  width: 100%;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border: 0;
  line-height: $line-height-s;
  font-size: $font-size-xs;
  transition: color .2s ease, border-color .2s ease;

  @include breakpoint(medium) {
    font-size: $font-size-s;
  }

  &:-webkit-autofill {
    box-shadow: inset 0 0 0 9999rem $white;
  }

  .has-error & {
    border-color: $c-red;
    -webkit-text-fill-color: $c-red;
  }

  &:focus,
  &.filled {
    border-color: $grey-80;

    + label {
      top: 0;
      left: 0;
      font-size: $font-size-xxs;

      @include breakpoint(medium) {
        font-size: $font-size-xs;
      }
    }

    .has-error & {
      border-color: $c-red;
      -webkit-text-fill-color: $c-red;
    }

  }
}

//Form
.form {
  &__fieldset {
    margin-bottom: 1rem;

    @include breakpoint(medium) {
      margin-bottom: 1.5rem;
    }
  }

  &__label {

    // Magazine
    &--magazin {
      .inner {
        display: flex;
        color: $grey-80;
        font-size: $font-size-xxs;
        line-height: $line-height-s;

        @include breakpoint(xlarge) {
          font-size: $font-size-s;
        }

        .label-title {
          font-weight: bold;

          @include breakpoint(xlarge) {
            margin-bottom: .5rem;
          }
        }

        .label-image {
	        width: rem-calc(80px);
	        max-width: rem-calc(80px); // overwrites image max-width: 100%
	        height: rem-calc(104px); // height: auto; runs into a bug in firefox
	        margin-right: .5rem;

          @include breakpoint(xlarge) {
	          width: rem-calc(104px);
	          max-width: rem-calc(104px); // overwrites image max-width: 100%
	          height: rem-calc(136px);  // height: auto; runs into a bug in firefox
	          margin-right: 1rem;
          }
        }
      }
    }
  }

  &__section-label {
    color: $grey-50;
    line-height: $line-height-s;
    font-size: $font-size-xs;

    @include breakpoint(medium) {
      font-size: $font-size-s;
    }
  }

  &__field {
    margin-bottom: 1rem;

    .form__multicheck & {
      margin-bottom: 1.5rem;

      @include breakpoint(medium) {
        margin-bottom: 2rem;
      }

      @include breakpoint(xlarge) {
        margin-bottom: 2.5rem;
      }
    }

    &__wrap {
      position: relative;
      line-height: 0;
      padding-top: 1.5rem;
    }
  }

  &__input {
    @extend %input-base;
    border-bottom: .125rem solid $grey-20;
    padding: .5rem 0;

    + label {
      @extend %label-base;
      top: 2rem;
    }
  }

  &__textarea {
    @extend %input-base;
    border: .125rem solid $grey-20;
    padding: 1rem;
    height: 10rem;

    @include breakpoint(medium) {
      height: rem-calc(224px);
    }

    + label {
      @extend %label-base;
      top: 2.5rem;
      left: 1rem;
    }
  }

  &__select {
    @extend %input-base;
    color: $grey-50;
    border-bottom: .125rem solid $grey-20;
    padding: .5rem 0;

    + label {
      @extend %label-base;
      top: 0;
      opacity: 0;
      font-size: $font-size-xxs;

      @include breakpoint(medium) {
        font-size: $font-size-xs;
      }
    }

    &__icon {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 2rem;
      pointer-events: none;

      svg {
        path {
          stroke: $grey-50;
          transition: stroke .2s ease;

          .has-error & {
            stroke: $c-red;
          }
        }
      }
    }

    &:focus,
    &.filled {
      color: $grey-80;

      + label {
        opacity: 1;
      }

      ~ .form__select__icon {
        svg {
          path {
            stroke: $grey-80;

            .has-error & {
              stroke: $c-red;
            }
          }
        }
      }
    }
  }

  &__checkbox {
    position: absolute;
    opacity: 0;
    left: -9999rem;

    + label {
      @extend %label-base;
      color: $grey-80;
      display: block;
      position: relative;
      cursor: pointer;
      padding-left: 2rem;

      @include breakpoint(medium) {
        padding-left: 2.5rem;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: $white;
        border: .125rem solid $grey-20;
        transition: border-color .2s ease;

        .has-error & {
          border-color: $c-red;
        }

        @include breakpoint(medium) {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .has-error & {
        color: $c-red;
      }
    }

    &:checked {
      + label {
        &:before {
          content: '';
          background: url('data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20viewBox%3D%220%200%2011%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10.6172%200.214948C11.0517%200.555819%2011.1276%201.18441%2010.7868%201.61895L5.59933%208.23186C4.78271%209.2729%203.1984%209.24828%202.41452%208.18237L0.194461%205.16359C-0.132742%204.71866%20-0.0373102%204.09273%200.407613%203.76553C0.852536%203.43833%201.47847%203.53376%201.80567%203.97868L4.02572%206.99746L9.21317%200.38455C9.55404%20-0.0499903%2010.1826%20-0.125924%2010.6172%200.214948Z%22%20fill%3D%22%23FE5000%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat center center;
          position: absolute;
          top: 0;
          left: 0;
          width: 1rem;
          height: 1rem;
          z-index: 1;

          @include breakpoint(medium) {
            background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M17.6172%208.21495C18.0518%208.55582%2018.1277%209.18441%2017.7868%209.61895L12.5994%2016.2319C11.7827%2017.2729%2010.1984%2017.2483%209.41453%2016.1824L7.19446%2013.1636C6.86726%2012.7187%206.96269%2012.0928%207.40761%2011.7656C7.85254%2011.4383%208.47847%2011.5338%208.80567%2011.9787L11.0257%2014.9975L16.2132%208.38455C16.5541%207.95001%2017.1827%207.87408%2017.6172%208.21495Z%22%20fill%3D%22%23FE5000%22%2F%3E%0A%3C%2Fsvg%3E') no-repeat center center;
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        &:after {
          border-color: $grey-80;
        }
      }
    }

    &__text {
      position: relative;
      top: -.25rem;
    }
  }

  &__radio {
    position: absolute;
    opacity: 0;
    left: -9999rem;

    + label {
      @extend %label-base;
      color: $grey-80;
      min-height: 1.5rem;
      line-height: 1.5rem;
      display: block;
      position: relative;
      cursor: pointer;
      padding-left: 2rem;

      @include breakpoint(medium) {
        padding-left: 3rem;
        min-height: 2rem;
        line-height: 2rem;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background: $white;
        border: .125rem solid $grey-20;
        border-radius: 50%;
        transition: border-color .2s ease;

        @include breakpoint(medium) {
          width: 2rem;
          height: 2rem;
        }

        .has-error & {
          border-color: $c-red;
        }
      }

      .has-error & {
        color: $c-red;
      }
    }

    &:checked {
      + label {
        &:before {
          content: '';
          background: $white;
          position: absolute;
          border-radius: 50%;
          z-index: 1;
          top: .425rem;
          left: .425rem;
          width: .625rem;
          height: .625rem;

          @include breakpoint(medium) {
            top: .625rem;
            left: .625rem;
            width: .75rem;
            height: .75rem;
          }
        }

        &:after {
          background: $la-orange;
          border-color: $grey-80;
        }
      }
    }
  }

  // Magazine
  &__multicheck {
    + label {
      display: none;
    }
  }
}
