.textmedia {
	$this: &;
	margin-bottom: 56px;

	.image {
		&--no-acc {
			width: calc(100% + 2.5rem);
			margin-left: -1.25rem;

			@include breakpoint(medium) {
				width: 100%;
				margin-left: 0;
			}
		}
	}

	@include breakpoint(medium) {
		margin-bottom: 80px;
	}

	@include breakpoint(xlarge) {
		margin-bottom: 124px;
	}

	&__image {
		&__wrapper {
			position: relative;
			display: block;

			@include breakpoint(xlarge) {

				width: 98.972%; //Fix to get width of 512px without changing the grid

				&.image-top {

					width: 99.002%; //Fix to get width of 1056px without changing the grid
				}
			}
		}
	}

	&__video {
		&__wrapper {
			position: relative;
			display: block;
			padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */

			& iframe,
			& video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				border: 0;
			}

			& video {

				object-fit: unset;
			}
		}
	}

	&__accordion {
		margin-bottom: 0;
		width: 100%; //Fix for correct Sizes of Videos inside Text and Media
	}

	&__headline {
		font-size: $font-size-s;
		line-height: $line-height-s;

		@include breakpoint(medium) {
			font-size: $font-size-m;
			line-height: $line-height-m;
		}

		@include breakpoint(xlarge) {
			font-size: $font-size-l;
			line-height: $line-height-l;
			padding-bottom: 8px;
		}

		.accordion__textmedia & {
			font-size: $font-size-xxs;
			line-height: $line-height-s;

			@include breakpoint(medium) {
				font-size: $font-size-xs;
			}

			@include breakpoint(xlarge) {
				font-size: $font-size-s;
			}
		}
	}

	&__bodytext {
		font-size: $font-size-xxs;
		line-height: $line-height-s;

		@include breakpoint(medium) {
			font-size: $font-size-xs;
			line-height: $line-height-s;
		}

		@include breakpoint(xlarge) {
			font-size: $font-size-s;
			line-height: $line-height-s;
		}

		.accordion__textmedia & {
			font-size: $font-size-xxs;
			line-height: $line-height-s;

			@include breakpoint(medium) {
				font-size: $font-size-xs;
			}

			@include breakpoint(xlarge) {

				font-size: $font-size-s;
			}
		}

		p {
			line-height: inherit;
			font-size: inherit;
		}

		a {
			color: $primary-color;
			text-decoration: underline;

			&:hover {
				color: $primary-color; // foundation overvwrite
				text-decoration: none;
			}
		}

		ul,
		ol {
			margin: 0 0 1rem;

			li {
				position: relative;
				margin: 0 0 .5rem;
				padding: 0 0 0 1.5rem;

				font-size: $font-size-xxs;
				line-height: $line-height-s;

				@include breakpoint(medium) {
					font-size: $font-size-xs;
					line-height: $line-height-s;
				}

				@include breakpoint(xlarge) {
					font-size: $font-size-s;
					line-height: $line-height-s;
				}

				&:last-child {
					margin: 0;
				}

				&:before {
					position: absolute;
					left: 0;
				}
			}
		}

		ul {

			li {

				&:before {
					top: .5rem;
					display: block;
					width: .5rem;
					height: .5rem;
					border-radius: 50%;
					background: $primary-color;
					content: '';
				}
			}
		}

		ol {
			counter-reset: section;

			li {
				counter-increment: item;

				&:before {
					top: 0;
					content: counter(item)'.';
					color: $primary-color;
					font-weight: $global-weight-bold;
				}
			}
		}
	}

	&__link {
		font-size: $font-size-xs;
		line-height: $line-height-s;
		font-weight: $global-weight-bold;
		padding-top: 16px;

		@include breakpoint(medium) {
			font-size: $font-size-xs;
			line-height: $line-height-s;
		}

		@include breakpoint(xlarge) {

			padding-top: 24px;
			font-size: $font-size-s;
			line-height: $line-height-s;
		}

		&__wrapper {
			display: flex;
			align-items: center;

			//Transitions on svg
			svg {
				fill: $white;
				transition: fill .3s ease-in-out;

				rect {
					stroke: $primary-color;
					transition: stroke .3s ease-in-out;
				}

				path {
					fill: $primary-color;
					transition: fill .3s ease-in-out;
				}
			}

			//Hover effects on svg
			&:hover {

				svg {
					fill: $primary-color;
					transition: fill .3s ease-in-out;

					rect {
						stroke: $white;
						transition: stroke .3s ease-in-out;
					}

					path {
						fill: $white;
						transition: stroke .3s ease-in-out;
					}
				}
			}
		}
	}

}
