//Styles for Burger Menu & Login Close Icon

// Width and Height of burger menu

//Small (16px * 14px)
$size-bm-width-s: 1rem;
$size-bm-height-s: .25rem; // (3 * 2px) border + (2* $size-bm-height-s) = 14px

//Medium & Large (24px * 18px)
$size-bm-width-m: 1.5rem;
$size-bm-height-m: .5rem; // (3 * 2px) border + (2* $size-bm-height-m) = 18px


.icon-menu {
	position: relative;
	height: $size-bm-width-m;
	width: $size-bm-width-m;
	border-radius: 3px;
	cursor: pointer;
	color: $grey-80;

	&__nav,
	&__login {
		position: absolute;
		top: .7rem;
		content: '';
		width: calc(#{$size-bm-width-s} - #{$size-bm-height-s});
		height: 2px;
	}

	&__nav {
		background: $grey-80;
		transition: background-color .2s ease-in-out, width .2s ease-in-out;

		@include breakpoint(medium) {
			width: calc(#{$size-bm-width-m} - #{$size-bm-height-m});
		}

		@include breakpoint(xlarge) {
			top: 1rem;
		}

		&:before,
		&:after {
			position: absolute;
			content: '';
			height: 2px;
			width: $size-bm-width-s;
			background: $grey-80;
			transition: transform .2s ease-in-out, top .2s ease-in-out, color .2s ease-in-out, background-color .2s ease-in-out;

			@include breakpoint(medium) {
				width: $size-bm-width-m;
			}
		}

		&:before {
			top: -$size-bm-height-s;

			@include breakpoint(medium) {
				top: -$size-bm-height-m;
			}
		}

		&:after {
			top: $size-bm-height-s;

			@include breakpoint(medium) {
				top: $size-bm-height-m;
			}
		}
	}

	& .is-open {
		background: $white;

		&:before {
			transform: rotate(45deg);
			top: 0;
			background: $grey-80;
		}

		&:after {
			transform: rotate(-45deg);
			top: 0;
			background: $grey-80;
		}
	}

	// Login Icon (is-open) Close Burger Menu
	&__login {
		left: 0;
		display: inline-block;
		visibility: hidden;
		opacity: 0;
		transition: visibility .2s ease-in-out, opacity .2s ease-in-out;

		@include breakpoint(medium) {
			width: calc(#{$size-bm-width-m} - #{$size-bm-height-m});
			top: $size-bm-width-s;
		}

		&:before,
		&:after {
			position: absolute;
			content: '';
			width: $size-bm-width-s;
			height: 2px;
			left: 0;
			background: $grey-80;

			@include breakpoint(medium) {
				width: $size-bm-width-m;
			}
		}

		&:before {
			transform: rotate(45deg);
			top: 0;
			background: $grey-80;
		}

		&:after {
			transform: rotate(-45deg);
			top: 0;
			background: $grey-80;
		}

		&.is-open {
			visibility: visible;
			opacity: 1;
		}
	}
}
