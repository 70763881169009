//Styles for News List Item
.news-item {

	margin-bottom: 24px;
	border-bottom: 2px solid $grey-10;

	display: flex;
	flex-direction: column;

	@include breakpoint(medium) {

		 flex-direction: row;
	 }

	@include breakpoint(xlarge) {

		margin-bottom: 32px;
	}

	&__image {

		padding-bottom: 32px;

		@include breakpoint(medium) {

			padding-right: 32px;
		}

		&--wrapper {
			position: relative;
			width: 100%;
			height: auto;

			a {
				display: block;
			}
		}
	}

	&__event {

		display: none;
	}

	&__text {

		&--headline {
			padding-top: 8px;
			font-size: $font-size-s;
			line-height: $line-height-s;

			@include breakpoint(xlarge) {
				font-size: $font-size-m;
				line-height: $line-height-m;
				padding-top: 16px;
			}
		}

		&--details {
			color: $grey-80;
			font-size: $font-size-xxs;
			line-height: $font-size-s;

			@include breakpoint(medium) {
				font-size: $font-size-xs;
			}
		}

		&--nodate {

			display: none;
		}

		&--teaser {

			padding-bottom: 24px;
			font-size: $font-size-xs;
			line-height: $font-size-s;
		}

		&--dash {
			color: $grey-80;
			font-size: $font-size-xxs;
			line-height: $font-size-s;
		}

		&--link {

			display: flex;
			align-items: center;

			padding-bottom: 22px;

			font-size: $font-size-xs;
			line-height: $font-size-s;
			font-weight: $global-weight-bold;

			@include breakpoint(xlarge) {

				font-size: $font-size-s;
			}

			//Transitions on svg
			svg {
				fill: $white;
				transition: fill .3s ease-in-out;

				rect {

					stroke: $primary-color;
					transition: stroke .3s ease-in-out;
				}

				path {

					fill: $primary-color;
					transition: fill .3s ease-in-out;
				}
			}

			//Hover effects on svg
			&:hover {

				svg {
					fill: $primary-color;
					transition: fill .3s ease-in-out;

					rect {

						stroke: $white;
					}

					path {

						fill: $white;
					}
				}
			}
		}
	}
}
