.icon {

	&__arrow {

		&--right,
		&--left {

			width: 24px;
			height: 24px;

			@include breakpoint(xlarge) {
				width: 32px;
				height: 32px;
			}
		}

		&--right {
			margin-left: 8px;

			@include breakpoint(xlarge) {
				margin-left: 16px;
			}
		}

		&--left {

			margin-right: 8px;
			transform: rotate(180deg);

			@include breakpoint(xlarge) {
				margin-right: 16px;
			}
		}
	}


	&__download {

		width: 16px;
		height: 16px;
		margin-right: 8px;

			@include breakpoint(xlarge) {
				width: 24px;
				height: 24px;
			}
	}
}
