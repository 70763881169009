.event-nav {

	&__list {
		display: block;
		color: $primary-color;

		&--element {
			margin-bottom: 6px;
			display: inline-block;

			@include breakpoint(medium) {
				margin-bottom: 0;
			}
		}
	}

	&__link {
		margin-right: 1rem;
		border-bottom: 3px solid $grey-20;
		transition: border-bottom-color .3s ease-in-out, color .3s ease-in-out;
		font-size: $font-size-xxs;
		line-height: $line-height-s;
		font-weight: $global-weight-bold;
		color: $grey-20;

		@include breakpoint(medium) {
			font-size: $font-size-xs;
			line-height: $line-height-s;
			margin-right: 1.5rem;
		}

		@include breakpoint(xlarge) {
			font-size: $font-size-xs;
			line-height: $line-height-s;
			margin-right: 2rem;
		}

		&.active {

			color: $primary-color;
			border-bottom: 3px solid $primary-color;
		}

		&:hover {

			border-bottom: 3px solid $primary-color;
			color: $primary-color;
		}
	}
}
